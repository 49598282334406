import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from '@/bundle/Layouts/ContentLayout/ContentLayout';
import { TransactionDetails } from './ui/TransactionDetails/TransactionDetails';
import { PageBody } from '@/components/Page/PageBody/PageBody';
import { WiresBlockHeaderTitle } from './styles';
import { Button } from '@/components/Button/Button';
import { ReactComponent as IconPlus } from '../../../../../images/PlusIcon.svg';
import { useEffect, useState } from 'react';
import { NoResults } from '@/components/NoResults/NoResults';
import { ReactComponent as NoWiresIcon } from '../../../../../images/NoWiresIcon.svg';
import { ModalWindow } from '@/components/ModalWindow/ModalWindow';
import { Paginator } from '@/components/Paginator/Paginator';
import { CreateWireForm } from '../../OutboundWire/_Edit/ui/CreateWireForm/CreateWireForm';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { useInterval } from '@/hooks/useInterval';
import { WireTypeFilter } from './ui/WireTypeFilter/WireTypeFilter';
import {
  WIRE_TYPE_ALL,
  WIRE_TYPE_FILTER_AMPLITUDE_EVENTS_MAP,
  WIRE_TYPE_FILTER_CONFIG,
  WireTypesType,
} from './const/const';
import { PageFooter } from '@/components/Page/PageFooter/PageFooter';
import { WiresList } from './ui/WiresList/WiresList';
import { WIRE_TYPE_DB } from '@/const/wire';
import { useQuery, keepPreviousData, useQueryClient, useMutation } from '@tanstack/react-query';
import { createWire, deleteTransaction, loadCurrency, loadTransactionWires } from './api';
import { currencyFormatter } from '@/helpers/formatHelpers';
import { isPendingWireStatus } from '@/helpers/wireHelpers';
import { Box } from '@/components/Box/Box';
import { getTransactionsUrl } from '../urls/getTransactionsUrl';
import { ConfirmModal } from '@/components/ConfirmModal/ConfirmModal';
import { getResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { showSnackbar } from '@/components/Snackbar/Snackbar';
import { SearchInput } from '@/bundle/shared/components/SearchInput/SearchInput';
import {
  SearchFilterRadioOptionType,
  TransactionsSearchFilters,
} from '@/bundle/_OrgAdmin/ui/TransactionsSearchFilters/TransactionsSearchFilters';
import {
  SEARCH_FILTER_RADIO_OPTION_TYPE,
  SearchFiltersType,
} from '@/bundle/_OrgAdmin/ui/TransactionsSearchFilters/const';
import { useFilterSearch } from '@/bundle/_OrgAdmin/hooks/useFilterSearch';
import { NoSearchResults } from '@/bundle/shared/components/NoSearchResults';

const WIRES_POLLING_INTERVAL = 30000; // 30 seconds;

export const TransactionDetailsPage = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { search, setSearch, submitSearch, clearSearch, searchFilters, changeSearchFilters, searchParams } =
    useFilterSearch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenRemoveTransactionModal, setIsOpenRemoveTransactionModal] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState('');

  const { data: currency } = useQuery({
    queryKey: ['load_currency'],
    queryFn: () => loadCurrency(),
  });

  const { data: transactionWires, isPending: isPendingTransactionWire } = useQuery({
    queryKey: ['load_wires', id, searchParams],
    queryFn: () => loadTransactionWires(id, searchParams),
    placeholderData: keepPreviousData,
  });

  const {
    mutate,
    data: createWireData,
    isPending,
    reset,
  } = useMutation({
    mutationKey: ['create_wire'],
    mutationFn: (payload) => {
      return createWire(id, payload);
    },
    onSuccess: (createdWireData) => {
      if (createdWireData.error) return;

      setIsOpen(false);
      queryClient.resetQueries({ queryKey: ['load_wires'] });

      const eventData = {
        amount: createdWireData.body?.amount,
        currency: createdWireData.body?.currency,
      };
      const wireTypeEvent =
        createdWireData.body?.type === WIRE_TYPE_DB.inbound
          ? AMPLITUDE_EVENTS.CreateInboundWireSuccess
          : AMPLITUDE_EVENTS.CreateOutboundWireSuccess;

      amplitudeService.logEvent(wireTypeEvent, eventData);
    },
  });

  const { mutate: deleteTransactionMutate, isPending: isPendingDeleteTransaction } = useMutation({
    mutationKey: ['delete_transaction'],
    mutationFn: () => {
      return deleteTransaction(id);
    },
    onSuccess: (deletedTransactionResponse) => {
      if (deletedTransactionResponse?.error) {
        const error = getResponseError(deletedTransactionResponse.error);

        setIsOpenRemoveTransactionModal(false);
        queryClient.invalidateQueries({ queryKey: ['load_wires'] });

        showSnackbar(error, { variant: 'error', autoClose: false });

        return;
      }

      amplitudeService.logEvent(AMPLITUDE_EVENTS.RemoveTransactionSuccess);
      navigate(getTransactionsUrl());
    },
  });

  useEffect(() => {
    if (searchFilters.byName) {
      return setSearchPlaceholder('Search by wire name');
    }

    if (searchFilters?.searchEmailTypes) {
      return setSearchPlaceholder('Search by email address');
    }
  }, [searchFilters]);

  const selectedWireType = searchFilters?.type;
  const wires = transactionWires?.body?.results;
  const hasWires = !!wires?.length;
  const totalCount = transactionWires?.body?.count;
  const hasPaginator = totalCount > searchParams?.limit;
  const hasSearchName = !!searchParams.name;

  const noAllTypeWires = !hasWires && selectedWireType === WIRE_TYPE_ALL && !isPendingTransactionWire && !hasSearchName;
  const hasWireInPendingStatus = wires?.some(({ status }) => isPendingWireStatus(status));
  const currencyOptions = currencyFormatter(currency?.body?.results);
  const noSearchResults = !hasWires && location.search && !isPendingTransactionWire;
  const isEmptyTransaction = !hasWires && !location.search && !isPendingTransactionWire;
  const showFilters = (hasWires && !isPendingTransactionWire) || (!hasWires && location.search);

  const confirmDeleteTransaction = () => {
    if (!id) {
      return;
    }

    deleteTransactionMutate();
  };

  const createWireHandler = () => {
    amplitudeService.logEvent(AMPLITUDE_EVENTS.CreateWireRedirect);

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    reset();
  };

  const selectWireType = (wireType: WireTypesType) => {
    amplitudeService.logEvent(WIRE_TYPE_FILTER_AMPLITUDE_EVENTS_MAP[wireType]);

    changeSearchFilters({ ...searchFilters, type: wireType });
  };

  const mergeSearchFilters = (filters: SearchFiltersType, type: SearchFilterRadioOptionType) => {
    changeSearchFilters(filters);

    if (type === SEARCH_FILTER_RADIO_OPTION_TYPE.NAME) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionDetailsSearchSelectNameFilter);
    }

    if (type === SEARCH_FILTER_RADIO_OPTION_TYPE.EMAIL) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionDetailsSearchSelectEmailFilter);
    }
  };

  const navigateToTransactions = () => {
    if (location.state?.pathname && location.state?.search) {
      navigate({ pathname: location.state.pathname, search: location.state.search });
    } else {
      navigate(getTransactionsUrl());
    }
  };

  const handleSubmitSearch = () => {
    if (!!search.length && searchParams.name !== search) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.TransactionDetailsSearchSubmit);
    }

    submitSearch();
  };

  const openRemoveTransaction = () => {
    setIsOpenRemoveTransactionModal(true);
    amplitudeService.logEvent(AMPLITUDE_EVENTS.RemoveTransactionRedirect);
  };

  // polling wire list logic
  const { cancelInterval, isIntervalRunning } = useInterval(() => {
    if (hasWires && hasWireInPendingStatus) {
      queryClient.invalidateQueries({ queryKey: ['load_wires'] });
    }
  }, WIRES_POLLING_INTERVAL);

  // Cancel polling logic
  useEffect(() => {
    const shouldCancelInterval = hasWires && !hasWireInPendingStatus && isIntervalRunning;

    if (shouldCancelInterval) {
      cancelInterval();
    }
  }, [hasWires, hasWireInPendingStatus, cancelInterval, isIntervalRunning]);

  return (
    <ContentLayout
      title='Transaction Details'
      backButton='Back to Transactions'
      onBack={navigateToTransactions}
      headerAction={
        noAllTypeWires && (
          <Button color='secondary' width={160} mobileStretch onClick={openRemoveTransaction}>
            Remove
          </Button>
        )
      }
    >
      <PageBody>
        <TransactionDetails />
        <Box>
          <Box flexDirection='column'>
            <Box justifyContent='space-between' alignItems='center' mb='24px'>
              <WiresBlockHeaderTitle>Wires</WiresBlockHeaderTitle>
              <Button iconPosition='left' icon={<IconPlus />} width={160} onClick={createWireHandler}>
                Create Wire
              </Button>
            </Box>
            {showFilters && (
              <Box display='flex' alignItems='flex-start' columnGap='20px' flexWrap='wrap'>
                <Box width='344px'>
                  <SearchInput
                    placeholder={searchPlaceholder}
                    value={search}
                    filters={
                      <TransactionsSearchFilters
                        nameLabel='Wire Name'
                        searchFilters={searchFilters}
                        onChangeSearchFilters={mergeSearchFilters}
                      />
                    }
                    onChange={(value) => setSearch(value)}
                    onClear={clearSearch}
                    onSubmit={handleSubmitSearch}
                  />
                </Box>
                <WireTypeFilter
                  selected={selectedWireType}
                  onSelect={selectWireType}
                  config={WIRE_TYPE_FILTER_CONFIG}
                />
              </Box>
            )}
          </Box>
          {hasWires && <WiresList list={wires} />}
          {isEmptyTransaction && (
            <NoResults marginTop={68} icon={<NoWiresIcon />} message='Please create your first wire.' />
          )}
          {noSearchResults && <NoSearchResults />}
        </Box>
      </PageBody>
      {hasPaginator && (
        <PageFooter>
          <Paginator page={searchParams.page} limit={searchParams.limit} total={totalCount} />
        </PageFooter>
      )}

      <ModalWindow isOpen={isOpen} headerText='Create Wire' isStartPositionHeader>
        <CreateWireForm
          onCreate={mutate}
          onClose={closeModal}
          currencyOptions={currencyOptions}
          selectedWireType={selectedWireType}
          isLoading={isPending}
          error={createWireData?.error}
        />
      </ModalWindow>

      <ConfirmModal
        isOpen={isOpenRemoveTransactionModal}
        isLoading={isPendingDeleteTransaction}
        header='Remove Transaction'
        body='You will not be able to work with this transaction once you remove it.'
        onClose={() => setIsOpenRemoveTransactionModal(false)}
        onConfirm={confirmDeleteTransaction}
      />
    </ContentLayout>
  );
};
